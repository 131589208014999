import { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import { useAuthState } from '../../../providers/authProvider';
import { ButtonPrimary, Checkbox, ModalClose } from '../../../common/components';
import * as colors from '../../../common/styles/colors';

type ConsentModalProps = {
  onClose: () => void;
  onConfirm: (tcId: string, reference: string) => void;
  show: boolean;
  termsAndConditions: Array<HildebrandApi.RootObject>;
};

const defaultProps = {
  show: true,
};

export const ConsentWrapper = styled.div`
  .modal-title {
    color: ${colors.hydroBlue};
  }

  .spacer {
    position: sticky;
    top: 0px;
    height: 5px;
    background-color: white;
  }

  .termsAndConditions {
    overflow-y: auto;
    max-height: 400px;
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 5px;

    h1 {
      font-size: 0.9rem;
      font-weight: 500;
    }

    h2 {
      font-size: 0.8rem;
      padding-top: 15px;
    }

    p {
      font-size: 0.7rem;
      padding-top: 10px;
    }

    li {
      font-size: 0.7rem;
      padding-top: 10px;
    }
  }

  .conset-checkbox {
    opacity: 1;
  }
`;

export const ConsentModal = (props: ConsentModalProps) => {
  const authContext = useAuthState();
  const { show } = props;
  const [comments, setComments] = useState<string>('');
  const [termsAndConditionsId, setTermsAndConditionsId] = useState<string>('');
  const [reference, setReference] = useState<string>('');
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState<boolean>(false);

  const title = 'Consent to smart meter data retrieval';
  const messageTop = 'We use a third party supplier to provide the granular data for your Smart meter consumption.';
  const messageBottom =
    'In order for you to access your Smart Meter data we need you to agree to the following terms and conditions.';

  const onConfirm = (e: any) => {
    e.preventDefault();
    props.onConfirm(termsAndConditionsId, reference);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight - 1) {
      setAcceptTermsAndConditions(true);
    }
  };

  const generateTermsAndConditionsHtml = (dataArray: Array<HildebrandApi.RootObject>): string => {
    let html = '';

    dataArray.forEach(data => {
      html += `<div>`;

      html += `<h1>${data.data.title}</h1>`;

      data.data.details.forEach(detail => {
        html += `<div>`;

        html += `<h2>${detail.title}</h2>`;

        if (detail.description) {
          html += `<p>${detail.description}</p>`;
        }

        if (detail.keys && detail.values) {
          html += `<ul>`;
          detail.keys.forEach(key => {
            html += `<li>${detail.values ? detail.values[key] : 'N/A'}</li>`;
          });
          html += `</ul>`;
        }

        if (detail.list) {
          html += generateTermsAndConditionsHtml(
            detail.list.map(d => ({ data: { title: '', lastUpdated: '', details: [d] } } as HildebrandApi.RootObject))
          );
        }

        html += `</div>`;
      });

      html += `</div>`;
    });

    return html;
  };

  useEffect(() => {
    setTermsAndConditionsId(props.termsAndConditions[0].tcId);
    setReference(props.termsAndConditions[0].reference);
    const termsAndConditions = generateTermsAndConditionsHtml(props.termsAndConditions);
    setComments(termsAndConditions);

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} size={'xl'} backdrop='static' centered>
        <form>
          <ConsentWrapper>
            <Modal.Header className='pt-0 pb-0 pr-0'>
              <Modal.Title>{title}</Modal.Title>
              <ModalClose onClick={() => props.onClose()} />
            </Modal.Header>
            <Modal.Body style={{ justifyContent: 'center', textAlign: 'left' }}>
              <p className='pt-2'>{messageTop}</p>
              <p>{messageBottom}</p>
              <div className='pt-1'>
                <div ref={containerRef} className='termsAndConditions'>
                  <div className='spacer'></div>
                  <div dangerouslySetInnerHTML={{ __html: comments }} />
                </div>
              </div>
              <Checkbox
                id='acceptTermsAndConditions'
                label='I have read and agree to the terms and conditions'
                className='pt-4 conset-checkbox'
                onChange={(event: any) => setAcceptTermsAndConditions(event.target.checked)}
                checked={acceptTermsAndConditions}
                disabled={true}
              />
            </Modal.Body>
            <Modal.Footer className='p-0' style={{ justifyContent: 'right', textAlign: 'center' }}>
              <div className='d-flex mt-3'>
                <ButtonPrimary
                  onClick={e => onConfirm(e)}
                  title='Confirm'
                  disabled={!acceptTermsAndConditions}
                  className='my-0'
                />
              </div>
            </Modal.Footer>
          </ConsentWrapper>
        </form>
      </Modal>
    </>
  );
};

ConsentModal.defaultProps = defaultProps;
