import styled from 'styled-components';
import { respondTo } from '../../../common/styles/mixins';

export const SmartMeterConsentWrapper = styled.div`
  padding: 0 1rem 1rem;

  ${respondTo.tablet`
    margin: 0;
    padding: 0 33px 33px 0;
  `}

  .account-view-title {
    margin-top: 35px;
  }

  .pill-toggle {
    flex-direction: column !important;

    .additional-text {
      padding-top: 15px;
    }

    ${respondTo.desktopXl`
      flex-direction: row!important;

      .additional-text {
        margin-left: auto;
        padding-top: 5px;
        padding-left: 15px;
      }
    `};
  }

  .mpanViewLink {
    cursor: pointer;
  }

  .downloadHhPage {
    width: 100%;

    ${respondTo.desktopXXl`
      width: 60%;
    `}
  }

  .statusBadge {
    padding: 0 !important;
    margin: 0 !important;
  }
`;

export const ConsentGridWrapper = styled.div`
  position: relative;
  min-height: 200px;

  tbody:after {
    background: none !important;
  }

  td.site-address-col {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td.outstandingIcon {
    padding-top: 15px;
    padding-right: 0;
  }
`;
