import { formatDate } from '../../utils/date/date';
import { SmartMeterConsentDataRowWrapper } from './SmartMeterConsentDataRow.styles';

type SmartMeterConsentDataRowProps = {
  data: Array<Api.ISmartMeterDetail>;
};

const getDate = (date: any): string => {
  if (date != null && date.length > 0) {
    const result: string = formatDate(date);
    return result;
  }
  return '';
};

export const SmartMeterConsentDataRow = (props: SmartMeterConsentDataRowProps) => {
  const smartMeterDetails = props.data?.map((item: Api.ISmartMeterDetail) => {
    return (
      <tr>
        <td>{item.meterSerialNumber}</td>
        <td>{getDate(item.configurationStartDate)}</td>
        <td>{getDate(item.configurationEndDate)}</td>
      </tr>
    );
  });

  return (
    <SmartMeterConsentDataRowWrapper>
      <table>
        <thead>
          <tr className='header'>
            <th>Meter serial number</th>
            <th>Smart meter start date</th>
            <th>Smart meter end date</th>
          </tr>
        </thead>
        <tbody>{smartMeterDetails}</tbody>
      </table>
    </SmartMeterConsentDataRowWrapper>
  );
};
