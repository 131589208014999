import { get } from '../../helpers/axiosWrapper';

export const getHildebrandTermsAndConditions = async (
  url: string | undefined,
  context: any
): Promise<Array<HildebrandApi.RootObject>> => {
  const headers: any = {};

  const apiUrl = 'hildebrand/termsconditions/current';

  return await get(`${url}/${apiUrl}`, { headers }, context);
};
