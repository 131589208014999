import { get } from '../../helpers/axiosWrapper';

export const getSmartMeterConsentGridData = async (
  url: string | undefined,
  context: any,
  data: any
): Promise<Common.IResult<Array<Api.ISmartMeterConsent>>> => {
  const headers: Api.ISmartMeterConsentGridParams = {
    customerAccountReference: data.customerAccountReference,
    page: data.page,
    pageSize: data.pageSize,
  };

  const apiUrl = '/consumption/consent-by-customer-account-reference';

  return await get(`${url}/${apiUrl}`, { headers }, context);
};

export const giveConsent = (
  url: string | undefined,
  context: any,
  tcId: string,
  reference: string,
  data: any
): Promise<Common.IResult<boolean>> => {
  const headers: Api.IConsentParams = {
    tcId,
    reference,
  };

  alert('TODO - 66578 - NOT YET IMPLEMENTED');

  // TODO - 65980
  const apiUrl = '/consumption/giveConsent';

  // return await get(`${url}/${apiUrl}/${essCustomerId}`, { headers }, context);

  // TODO - 65980
  // Return Mock Data
  const result: Common.IResult<boolean> = {
    success: true,
    value: {
      data: true,
    },
    data: true,
    errors: [],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(result);
    }, 500);
  });
};

// TODO - 65980
export const refuseConsent = async (url: string | undefined, context: any, data: any): Promise<any> => {
  const headers: Api.IRefuseConsentParams = {
    // TODO - 65980
  };

  // TODO - 65980
  alert('TODO - 68278 - NOT YET IMPLEMENTED');
  const apiUrl = '/consumption/refuseConsent';

  // return await get(`${url}/${apiUrl}/${essCustomerId}`, { headers }, context);

  // TODO - 65980
  // Return Mock Data
  const result: Common.IResult<boolean> = {
    success: true,
    value: {
      data: true,
    },
    data: true,
    errors: [],
  };

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(result);
    }, 500);
  });
};
