import { useContext, useState } from 'react';

// Styles
import { AccountHealthWrapper } from './AccountHealth.styles';

import { Health, IHealthCheck } from '../Health/Health';
import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { useFetchHealthChecks } from '../hooks/useFetchHealthChecks';
import { useHistory } from 'react-router-dom';
import { SearchDetailContext, SearchDetailContextProps } from '../../../context/searchDetail/searchDetailContext';
import { RoutingToTypes } from '../../search/SearchActions/SearchActions';
import { ModalTemplate } from '../../mymessages/modalTemplates/ModalTemplate';
import { BlankModal } from '../../mymessages/modalTemplates/BlankModal';
import { HealthWidget } from '../HealthWidget/HealthWidget';
import { HealthCheckCountModal } from '../../mymessages/modalTemplates/HealthCheckCountModal';

type AccountHealthProps = {
  status: 'loading' | 'healthy' | 'notHealthy';
};

const defaultProps = {
  status: 'loading',
};

export const AccountHealth = (props: AccountHealthProps) => {
  const searchContext = useContext<SearchDetailContextProps>(SearchDetailContext);
  const history = useHistory();

  const { userDetail, setUserDetail } = useContext<UserDetailContextProps>(UserDetailContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [messagevalues, setMessageValues] = useState<Messages.IMessagesValues>();
  const [showExpiredCertificatesModal, setShowExpiredCertificatesModal] = useState<boolean>(false);
  const [mpanCountValues, setMpanCountValues] = useState<Array<Common.IKeyValue>>([]);
  const [showCount, setShowCount] = useState<boolean>(false);

  const hc: Array<IHealthCheck> = [
    {
      type: 'AccountOverdue',
      display: 'Overdue balance',
      route: '/home/site-details',
      redirect: RoutingToTypes.siteDetails,
      status: 'loading',
      url: '/ersgatewayadapter/account-health',
    },
    {
      type: 'ContractExpiry',
      display: 'Contract due to expire',
      route: '/home/contract-summary',
      redirect: RoutingToTypes.contracts,
      status: 'loading',
      url: '/ersgatewayadapter/account-health',
    },
    {
      type: 'DirectDebitSetup',
      display: 'No Direct Debit set up',
      route: '/home/site-details',
      redirect: RoutingToTypes.siteDetails,
      status: 'loading',
      url: '/ersgatewayadapter/account-health',
    },
    {
      type: 'DeemedRates',
      display: 'Deemed rates are being used',
      route: '/home/contract-summary',
      redirect: RoutingToTypes.contracts,
      status: 'loading',
      url: '/ersgatewayadapter/account-health',
    },
    {
      type: 'ExpiryCertificates',
      display: 'Certificate(s) due to expire',
      route: '/home/',
      redirect: RoutingToTypes.undefined,
      status: 'loading',
      url: '/ersgatewayadapter/account-health',
    },
    {
      type: 'SmartMeterConsent',
      display: 'Smart meter consent required',
      route: '/home/energy-usage/smartmeterconsent',
      redirect: RoutingToTypes.undefined,
      status: 'loading',
      url: '/consumption/consent-health',
    },
  ];

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseExpiredCertificatesModal = (event: any) => {
    setShowExpiredCertificatesModal(false);
  };

  const onMoreInfoClick = (healthCheck: IHealthCheck) => {
    const valueName =
      healthCheck.type === 'SmartMeterConsent' ? 'customerAccountReference' : 'customer-account-reference';

    if (!healthCheck.response) return;
    const uniqueAccounts = Array.from(new Set(healthCheck.response.map((item: any) => item[valueName])));

    if (healthCheck.type === 'ExpiryCertificates') {
      setShowExpiredCertificatesModal(true);
    } else {
      if (uniqueAccounts.length === 1) {
        const accountId = healthCheck.response[0][valueName];

        searchContext.setSearchDetail({
          ...searchContext.searchDetail,
          type: 'account',
          accountId: accountId,
          routingTo: healthCheck.redirect,
          searchText: accountId,
        });
        setUserDetail({
          ...userDetail,
          selectedCustomerAccount: accountId,
        });

        history.push(healthCheck.route);
      } else {
        let response = '';
        if (healthCheck.response) {
          response = JSON.stringify(healthCheck.response);
          response = JSON.parse(response);
        }

        const messageValues = {
          ids: [],
          date: '',
          time: '',
          title: '',
          callToAction: '',
          route: healthCheck.route,
          subject: '',
          accountReference: '',
          json: response,
          routingTo: healthCheck.redirect,
        };

        const mpanCounts = Array.from(
          new Set(
            healthCheck.response.map((item: any) => {
              return {
                key: item[valueName],
                value: `#${item['mpans']?.length} Meters`,
              };
            })
          )
        );

        setMpanCountValues(mpanCounts);

        switch (healthCheck.type) {
          case 'AccountOverdue': {
            setMessageValues({
              ...messageValues,
              title: 'The following accounts have an overdue balance',
              callToAction: 'Account details',
            });
            setShowCount(false);
            setShowModal(true);
            break;
          }
          case 'ContractExpiry': {
            setMessageValues({
              ...messageValues,
              title: 'The following accounts have contracts that are due to expire',
              callToAction: 'Contract summary',
            });
            setShowCount(false);
            setShowModal(true);
            break;
          }
          case 'DirectDebitSetup': {
            setMessageValues({
              ...messageValues,
              title: 'The following accounts require a valid Direct Debit to be set up',
              callToAction: 'Account details',
            });
            setShowCount(false);
            setShowModal(true);
            break;
          }
          case 'DeemedRates': {
            setMessageValues({
              ...messageValues,
              title: 'The following accounts have contracts that are on deemed rates',
              callToAction: 'Contract summary',
            });
            setShowCount(false);
            setShowModal(true);
            break;
          }
          case 'SmartMeterConsent': {
            setMessageValues({
              ...messageValues,
              title: 'The following accounts require smart meter consent',
              callToAction: 'Smart meter consent',
            });
            setShowCount(true);
            setShowModal(true);
            break;
          }
        }
      }
    }
  };

  const { healthChecks } = useFetchHealthChecks(hc, userDetail.customerAccounts, props.status);

  return (
    <AccountHealthWrapper>
      <HealthWidget
        title='Account health'
        type='AccountHealth'
        healthChecks={healthChecks}
        checkingText='Account Health'
        healthyText='Account health check complete'
        customers={userDetail.customerAccounts}
        onMoreInfoClick={onMoreInfoClick}
      ></HealthWidget>

      <BlankModal
        show={showExpiredCertificatesModal}
        onHide={handleCloseExpiredCertificatesModal}
        titleText={'There are certificates that are due to expire shortly'}
        bodyText={'Please contact our service team to discuss the renewal of your certificates.'}
        buttonText={'Ok'}
        contactEmail={'majorbusinesscustomerservice@drax.com'}
        contactNumber={'01473 707748'}
      ></BlankModal>

      {showCount ? (
        <HealthCheckCountModal
          messagevalues={messagevalues}
          show={showModal}
          onHide={handleCloseModal}
          counts={mpanCountValues}
        />
      ) : (
        <ModalTemplate
          name={'HealthCheckModal'}
          messagevalues={messagevalues}
          show={showModal}
          onHide={handleCloseModal}
        />
      )}
    </AccountHealthWrapper>
  );
};

AccountHealth.defaultProps = defaultProps;
