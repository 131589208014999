import { useContext, useEffect, useReducer, useState } from 'react';

import { UserDetailContext, UserDetailContextProps } from '../../../context/userDetail/userDetailContext';
import { GRID_ACTIONS, gridReducer, IGridReducerState } from '../../../reducer/gridReducer/gridReducer';
import SmartMeterConsentPage from './SmartMeterConsentPage';
import { getSelectedCustomerId } from '../../../helpers/customer';
import { globalApiParams } from '../../../common/api/globals';
import { getSmartMeterConsentGridData, giveConsent, refuseConsent } from '../../../common/api/consentApi';
import { useAuthState } from '../../../providers/authProvider';
import { getHildebrandTermsAndConditions } from '../../../common/api/hildebrandApi';
import { toast } from 'react-toastify';

const SmartMeterConsent = () => {
  const { userDetail } = useContext<UserDetailContextProps>(UserDetailContext);
  const authContext = useAuthState();

  const initialState: IGridReducerState = {
    page: 1,
    currentType: '',
    data: {},
    initialSortField: 'consent',
    initialSortOrder: 'DESC',
    currentSortField: 'consent',
    currentSortOrder: 'ASC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: getSelectedCustomerId(userDetail, true),
  };

  const [termsAndConditions, setTermsAndConditions] = useState<Array<HildebrandApi.RootObject>>([]);

  const [state, dispatch] = useReducer(gridReducer, initialState);

  const formatSmartMeterConsentData = (data: Array<Api.ISmartMeterConsent>) => {
    data.map(item => {
      if (!item.consentExpiry) item.consentExpiry = 'N/A';
      if (item.consent === 'Not set') {
        item.consentExpiry = '';
        item.consentUpdated = '';
      }
      item.meters.map(meterItem => {
        if (!meterItem.configurationEndDate) meterItem.configurationEndDate = '';
      });
      return item;
    });
    return data;
  };

  const loadData = async () => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const params: Api.ISmartMeterConsentGridParams = {
      customerAccountReference: state.selectedCustomerId,
      page: state.page,
      pageSize: globalApiParams.pageSize,
    };

    // TOOD - 65980
    const data = getSmartMeterConsentGridData(process.env.REACT_APP_API_URL, authContext, params);

    data.then((result: Common.IResult<Array<Api.ISmartMeterConsent>>) => {
      if (result && result.success && result.data.length > 0) {
        const data = formatSmartMeterConsentData(result.data);
        dispatch({ type: GRID_ACTIONS.ADD_DATA, payload: { data: data, page: state.page } });
      } else {
        dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
      }
      dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
    });
  };

  useEffect(() => {
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });

    // Don't load if no customer selected
    if (!state.selectedCustomerId) return;

    loadData();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedCustomerId, state.page]);

  const handleCustomerFilterChange = (option: Common.IOption) => {
    dispatch({ type: GRID_ACTIONS.FILTER_BY_CUSTOMER, payload: { essCustomerId: option.value } });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const loadTermsAndConditions = async () => {
    const data: Array<HildebrandApi.RootObject> = await getHildebrandTermsAndConditions(
      process.env.REACT_APP_API_URL,
      authContext
    );

    if (data != null) {
      if (data.length === 0) {
        const message = 'Terms and conditions have not been setup correctly';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      } else {
        setTermsAndConditions(data);
      }
    }
  };

  const handleConsentGiveClick = () => {
    loadTermsAndConditions();
  };

  const handleConsentConfirm = (tcId: string, reference: string, items: Array<Api.ISmartMeterConsent>) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    // await
    // TOOD - 65980
    const result = giveConsent(process.env.REACT_APP_API_URL, authContext, tcId, reference, items);

    result.then((data: any) => {
      dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

      // Refresh the grid
      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
      loadData();
    });
  };

  const handleConsentRefuse = (items: Array<Api.ISmartMeterConsent>) => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    // await
    // TOOD - 65980
    const result = refuseConsent(process.env.REACT_APP_API_URL, authContext, items);

    result.then((data: any) => {
      dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });

      // Refresh the grid
      dispatch({ type: GRID_ACTIONS.REINIT, payload: initialState });
      loadData();
    });
  };

  return (
    <SmartMeterConsentPage
      gridData={state.gridData}
      isLoading={state.isLoading}
      termsAndConditions={termsAndConditions}
      handlePagingChange={handlePagingChange}
      handleCustomerFilterChange={handleCustomerFilterChange}
      handleConsentGiveClick={handleConsentGiveClick}
      handleConsentConfirm={handleConsentConfirm}
      handleConsentRefuse={handleConsentRefuse}
    ></SmartMeterConsentPage>
  );
};

export default SmartMeterConsent;
