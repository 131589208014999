import { useEffect, useState } from 'react';

// Styles
import { EnergyUsageWrapper } from './energyusage.styles';

// Types
import { IMpanOption } from '../ConfigurableChart/ChartTypes';

// Components
import { Overview } from './Overview';
import { IndividualSites } from './IndividualSites';
import { StyledTitle, SmartMeterBanner } from '../../common/components';
import { isDraxBrand } from '../../utils/common';
import { TogglePill } from '../../common/components/TogglePill/TogglePill';
import { SmartMeterModal } from '../ConfigurableChart/SmartMeterModal/SmartMeterModal';

const EnergyUsage = () => {
  const [toggleValue, setToggleValue] = useState<string>('Account view');
  const [isShowingSmartMeterModal, setIsShowingSmartMeterModal] = useState(false);
  const [askForCustomerAccount, setAskForCustomerAccount] = useState<boolean>(true);
  const [isSmart, setIsSmart] = useState<boolean>(false);

  useEffect(() => {
    setIsSmart(false);
  }, [toggleValue]);

  const toggleClick = (value: 'Account view' | 'MPAN view') => {
    setToggleValue(value);
    setAskForCustomerAccount(false);
  };

  const handleSelectedMpanChanged = (mpanData: IMpanOption) => {
    const smartMeter = mpanData && mpanData.smartMeter ? mpanData.smartMeter : false;
    setIsSmart(smartMeter);
  };

  return (
    <EnergyUsageWrapper>
      <StyledTitle>Energy usage data</StyledTitle>
      <div className='d-flex-column'>
        <div className='my-3'>
          You can view your electricity usage at account and individual MPAN level.
          <br />
          Note that:
          <br />
          <ol className='font-italic'>
            <li>If you don't have a smart meter, we base some of your readings on estimates.</li>
            <li>Monthly data may not align with the dates of your monthly bills.</li>
          </ol>
          <div className='d-flex pill-toggle'>
            <TogglePill item1='Account view' item2='MPAN view' onClick={toggleClick}></TogglePill>

            {toggleValue == 'MPAN view' && isSmart && (
              <div className='additional-text'>
                To see consumption data for this MPAN in more detail, view your&nbsp;
                <a
                  className='link-primary mpanViewLink'
                  onClick={() => setIsShowingSmartMeterModal(!isShowingSmartMeterModal)}
                >
                  smart meter data
                </a>
                .
              </div>
            )}
          </div>
        </div>
      </div>

      {toggleValue == 'Account view' && <Overview askForCustomerAccount={askForCustomerAccount} />}
      {toggleValue == 'MPAN view' && <IndividualSites handleSelectedMpanChanged={handleSelectedMpanChanged} />}

      {isDraxBrand && (
        <div className='px-0 px-xl-4'>
          <SmartMeterBanner />
        </div>
      )}
      {isShowingSmartMeterModal && (
        <SmartMeterModal onClose={() => setIsShowingSmartMeterModal(!isShowingSmartMeterModal)} />
      )}
    </EnergyUsageWrapper>
  );
};

export default EnergyUsage;
