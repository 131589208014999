import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import * as colors from '../../../common/styles/colors';
import { ButtonPrimary, ModalClose } from '../../../common/components';

type RefuseConsentModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  show: boolean;
};

const defaultProps = {
  show: true,
};

export const RefuseConsentWrapper = styled.div`
  .modal-title {
    color: ${colors.hydroBlue};
  }
`;

export const RefuseConsentModal = (props: RefuseConsentModalProps) => {
  const { show } = props;
  const title = 'Refuse consent to smart meter data retrieval';
  const messageTop = 'By refusing consent, we will not be able to provide you with granular consumption data.';
  const messageBottom = 'Please click confirm to proceed.';

  const onConfirm = (e: any) => {
    e.preventDefault();
    props.onConfirm();
  };

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} size={'lg'} backdrop='static' centered>
        <RefuseConsentWrapper>
          <Modal.Header className='pt-0 pb-0 pr-0'>
            <Modal.Title>{title}</Modal.Title>
            <ModalClose onClick={() => props.onClose()} />
          </Modal.Header>
          <Modal.Body style={{ justifyContent: 'center', textAlign: 'left' }}>
            <p className='pt-2'>{messageTop}</p>
            <p>{messageBottom}</p>
          </Modal.Body>
          <Modal.Footer className='p-0' style={{ justifyContent: 'right', textAlign: 'center' }}>
            <div className='d-flex mt-3'>
              <ButtonPrimary onClick={e => onConfirm(e)} title='Confirm' className='my-0' />
            </div>
          </Modal.Footer>
        </RefuseConsentWrapper>
      </Modal>
    </>
  );
};

RefuseConsentModal.defaultProps = defaultProps;
