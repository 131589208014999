import styled from 'styled-components';

import * as colors from '../../../common/styles/colors';

export const SmartMeterConsentDataRowWrapper = styled.div`
  table {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    tr {
      th {
        font-weight: normal;
        background-color: ${colors.draxLightBg} !important;
      }

      td {
        padding-top: 0px;
      }
    }
  }
`;
